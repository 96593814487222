<template>
  <div>
    <div v-if="showEsito">
      <span class="text-avviso">{{saveText}}</span>
    </div>
    <div class="moduli" v-if="showlist && modelli && !showEsito">
      <div v-for="(item, key) in documenti" :key="item.id">
        <button class="button" v-bind:class="{ 'checked': item.stato }" :id="key" @click="showmodule(key)">
          <i class="button__icon icon icon-write"></i>
          <span>{{item.nome}}</span>
          <span class="checkedbutton" v-if="item.stato"><i class="fas fa-check"></i></span>
        </button>
      </div>
      <div class="alert alert-danger" v-show="textMessage!=''">{{textMessage}}</div>
      <div class="button-start-container" @click="checkcompiled">
        <div class="button-blue">
          <span class="button-label">
          {{$t('saveBtnLabel')}}
          </span>
        </div>
      </div>
    </div>
    <div class="informativa" v-if="!showlist && !showEsito">
      <Simple v-if="type=='0'" :currentText="currentText" :currentTitle="currentTitle" v-on:saveFrom="save"/>
      <DinamicModel v-if="type=='1'" :currentIndex="currentIndex" :currentNode="currentNode" :currentTitle="currentTitle" :save = "save"/>
    </div>
  </div>
</template>

<script>
import Simple from '../components/Simple.vue'
import DinamicModel from '../components/DinamicModel.vue'
import { mapGetters, mapState } from "vuex";
import axios from "axios";
import {openDB} from 'idb';
const db = openDB('SignatiApp', 2, {
  upgrade(db) {
    db.createObjectStore('modules');
  },
});

export default {
  name: 'Modules',
  components:{
    Simple,
    DinamicModel
  },
  computed: {
    ...mapGetters(["loading","modelli"])
  },
  methods: {
    getModuli:function() {
      axios({
        //url: 'http://localhost/signati/dashboard/getdocuments.php',
        url: 'https://consent.forclinic.cloud/getdocuments.php',
        method: 'POST',
        headers:{
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: 'token='+localStorage.getItem("user-token")
      })
      .then(resp => {
        this.clearDb();
        var modeliJson = resp.data;
        for(var i=0; i < modeliJson.length; i++){
          this.saveDb(i,modeliJson[i])
        }
        this.getKeys().then(resp => {
          resp.forEach(element => {
            this.getItem(element).then(resp => {
              this.documenti.push(resp)
            }).catch(err => { console.log(err) });
          });
        }).catch(err => { console.log(err) });
      })
      .catch(err => {
        console.log(err)
      });
    },
    showmodule: function(id){
      this.currentId = id;
      this.getItem(id).then(resp => {
        var currentJ = resp
        this.currentIndex = id;
        this.currentTitle = currentJ.title;
        if(currentJ.tipo==0){
          currentJ.text = this.replaceString(currentJ.text);
          this.currentText = this.replaceString(currentJ.text);
        }
        if(currentJ.tipo==1){
          this.currentNode = currentJ.components;
        }
        this.showlist = false;
        this.type = currentJ.tipo;
        this.delDb(id)
        this.saveDb(id,currentJ)
      }).catch(err => { console.log(err) });
    },
    async saveDb(key, val) {
      return (await db).put('modules', val, key);
    },
    async delDb(key) {
      return (await db).delete('modules', key);
    },
    async clearDb() {
      return (await db).clear('modules');
    },
    async getKeys() {
      return (await db).getAllKeys('modules');
    },
    async getItem(key) {
      return (await db).get('modules', key);
    },
    checkcompiled: function(){
      var validate = true;
      this.textMessage = '';
      this.documenti.map((item) => {
        if(item.stato===0){
          validate = false;
        }
      });
      if(validate){
        this.saveText = '';
        this.$store.commit('loadingT');
        const sendData = {
          form: JSON.stringify(localStorage.getItem('user-form')),
          json: JSON.stringify(this.documenti),
          token: JSON.stringify(localStorage.getItem('user-token')),
          lang: 'it'
        };
        axios({
          //url: 'http://localhost/signati/dashboard/save.php',
          url: 'https://consent.forclinic.cloud/save.php',
          method: 'POST',
          data: sendData
        })
        .then(resp => {
          if(resp.data.success=='true'){
            this.$store.commit('loadingF');
            this.textMessage = '';
            this.showlist = false;
            this.showEsito = true;
            this.saveText = this.$i18n.messages[this.$i18n.locale].saveText;
            setInterval(() => {
              this.clearDb();
              localStorage.removeItem("user-models");
              localStorage.removeItem("user-variabili");
              localStorage.removeItem("user-form");
              axios({
                //url: 'http://localhost/signati/dashboard/deletesession.php',
                url: 'https://consent.forclinic.cloud/deletesession.php',
                method: 'POST',
                data: {token:JSON.stringify(localStorage.getItem('user-token'))}
              })
              .then(resp => {
                this.$router.go(0)
              });
            }, 4000);
          }else{
            this.showlist = true;
            this.showEsito = false;
            this.$store.commit('loadingF');
            this.textMessage = 'Si è verificato un errore. Si prega di riprovare!';
          }
        })
        .catch(err => {
          this.showlist = true;
          this.showEsito = false;
          this.$store.commit('loadingF')
          this.textMessage = 'Si è verificato un errore. Si prega di riprovare!';
          console.log(err)
        });
      }else{
        this.textMessage = 'I modelli non sono stati correttamente compilati!';
      }
    },
    async getDb() {
      return new Promise((resolve, reject) => {
        let request = window.indexedDB.open('SignatiApp', 1);
        request.onerror = e => {
          console.log('Error opening db', e);
          reject('Error');
        };
       request.onsuccess = e => {
          resolve(e.target.result);
        };
       request.onupgradeneeded = e => {
          console.log('onupgradeneeded');
          let db = e.target.result;
          let objectStore = db.createObjectStore("modules", { autoIncrement: true, keyPath:'id' });
        };
      });
    },
    replaceString: function(string){
      let stringa = string.toString();
      var vars = JSON.parse(localStorage.getItem('variabili'));
      var form = JSON.parse(localStorage.getItem('user-form'));
      for(var i=0;i<vars.length; i++){
        var val = vars[i].value.toString();
        val = val.replace(/\?\?_/,'').replace(/_\?\?/,'');
        var regex = new RegExp(`\\?\\?_${val}_\\?\\?`, 'gi');
        stringa = stringa.replace(regex, form['var-'+i]);
      }
      return stringa;
    },
    save(firma) {
      this.textMessage = '';
      if(firma!=''){
        this.getItem(this.currentId).then(resp => {
          var current = resp
          current.sign = firma;
          if(current.tipo=='0'){
            current.text = this.replaceString(current.text);
          }
          current.stato = true;
          this.delDb(this.currentId)
          this.saveDb(this.currentId,current)
            this.documenti = [];
            this.getKeys().then(resp => {
              resp.forEach(element => {
                this.getItem(element).then(resp => {
                  this.documenti.push(resp)
                }).catch(err => { console.log(err) });
              });
            }).catch(err => { console.log(err) });
        }).catch(err => { console.log(err) });
      }else{
        var documentTemp = this.documenti;
        this.documenti = [];
        this.getItem(this.currentId).then(resp => {
          documentTemp[this.currentId] = resp;
          this.documenti = documentTemp
        }).catch(err => { console.log(err) });
      }
      this.showlist = true;
      this.showSign = false;
    }
  },
  data() {
    return {
      documenti: [],
      showlist: true,
      caricati: false,
      showSign: false,
      currentText: '',
      currentTitle: '',
      currentId : 0,
      textMessage: '',
      currentIndex: '',
      saveText: '',
      showEsito: false,
      type: '0',
      currentNode: [],
    };
  },
  mounted: function mounted(){
    this.getModuli();
  }
}
</script>
