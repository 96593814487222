<template>
    <div>
      <h1>{{currentTitle}}</h1>
      <div class="testo center" v-html="currentText">
      </div>
      <div>
        <div class="bottoni">
          <a class="consento" id="consento" @click="showSign = !showSign" :class="{'clicked': showSign}">
            <p>
              <span class="bg"></span>
              <span class="base"></span>
              <span class="text">{{$t('doConsenso')}}</span>
            </p>
          </a>
          <a class="nego" id="nego" @click="showSign = !showSign">
            <p>
              <span class="bg"></span>
              <span class="base"></span>
              <span class="text">{{$t('negoConsenso')}}</span>
            </p>
          </a>
        </div>
      </div>
      <div v-if="showErrorSign" class="alert alert-danger">{{$t('signAlert')}}</div>
      <div class="wrapper" id="wrapper" v-if="showSign">
        <div id="signature-pad" class="signature-pad">
          <div class="signature-pad--body">
            <VueSignaturePad ref="signaturePad" />
          </div>
          <div class="signature-pad--footer">
            <div class="description">{{$t('signLabelTop')}}</div>
            <div class="signature-pad--actions">
              <div>
                <button class="button-sign clar" @click="undo">{{$t('cancelSign')}}</button>
              </div>
              <div>
                <button class="button-sign save" @click="saveFrom">{{$t('confirmSign')}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Simple',
  props: {
    currentText: '',
    currentTitle: ''
  },
  data(){
      return{
          showSign: false,
          showErrorSign: false
      }
  },
  methods: {
    undo() {
      this.$refs.signaturePad.clearSignature();
    },
    saveFrom() {
        var firma = this.$refs.signaturePad.saveSignature().data;
        if(firma!='' && firma!=undefined){
          this.showErrorSign = false;
          this.$emit('saveFrom',firma)
          this.$refs.signaturePad.clearSignature();
        }else{
          this.showErrorSign = true;
        }
    }
  }
}
</script>
